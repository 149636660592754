.dT {
    padding: 0 5px !important;
    position: relative;
    top: 1px;
}
ion-card {
    width: 90%;
    min-width: 90%;
    max-width: 900px;
    margin: 30px auto;
}
ion-card.loginCard {
    max-width: 800px;
    min-width: auto;
    margin: 0 auto;
}
.calIcon {
    padding-left: 5px;
}
.license {
    pointer-events: none;
}
.userName {
    text-transform: capitalize;
}
.rampSelect {
    padding: 0 5px 0 0;
}
.taller {
    padding: 0 0;
    cursor: pointer;
}
.logo {
    width:200px;
    height:200px;
    margin: 0 auto;
}
.vertical-center {
    /* margin: 0; */
    /* position: absolute; */
    top: 47%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
/* .bookingsCard {
    height: 72.5vh;
    overflow-y: scroll;
} */
.bookingsCardContent {
    margin-top: 20px;
}
.bookingsModal {
    overflow-y: scroll;
}
.bold {
    font-weight: 800;
}
.checkSpin {
    margin: 7px 1px 0 0;
    height: 33px;
    width: 33px;
}
.status {
    font-size: 24px;
    margin-right: 10px;
}
.suggestion {
    font-size: 20px;
}
.menuItem {
    cursor: pointer;
}
@media screen and (min-width: 768px) {
    .picker-wrapper.sc-ion-picker-ios {
        bottom: 40%;
    }
    /* .title-default {
        max-width: 50%;
    } */
}

@media screen and (max-width: 767px) {
    ion-card {
        max-width: auto;
        margin: 25px 5%;
    }
    .taller {
        padding: 10px 0;
    }
}